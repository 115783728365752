






























































import {
  computed,
  defineComponent,
  onMounted,
  ref,
  useStore,
} from '@nuxtjs/composition-api'
import { getColorTitle } from '../composition/coloredTitle'
import { Logger } from '../composition/Logger'
import { sectionCoreProps } from '@/mixins/sectionCore'
import BlockBreadCrumbs from '@/components/BlockBreadCrumbs.vue'

export default defineComponent({
  name: 'SectionOsagoHero',
  components: { BlockBreadCrumbs },
  mixins: [sectionCoreProps],
  props: {
    description: {
      type: String,
      default: '',
    },
    iframe: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    badgeIcon: {
      type: String,
      default: '',
    },
    badgeText: {
      type: String,
      default: '',
    },
  },
  setup() {
    const store = useStore()
    const iframeContainer = ref<HTMLDivElement>()

    const sizes = {
      sm: [343, 180],
      md: [720, 290],
      lg: [646, 340],
    }

    const isDesktop = computed(() => store.getters['mediaQuery/isDesktopMq'])

    onMounted(() => {
      if (iframeContainer.value) {
        Array.from(iframeContainer.value.querySelectorAll('script')).forEach(
          (script) => {
            if (script.src) {
              const recaptchaScript = document.createElement('script')
              recaptchaScript.src = script.src
              for (let i = 0; i < script.attributes.length; i++) {
                recaptchaScript.setAttribute(
                  script.attributes[i].name,
                  script.attributes[i].value
                )
              }
              iframeContainer.value.appendChild(recaptchaScript)
              script.remove()
            } else if (script.innerHTML) {
              try {
                // eslint-disable-next-line no-eval
                eval(script.innerHTML)
              } catch (err) {
                Logger.error(err, 'IFrame loading error')
              }
            }
          }
        )
      }
    })

    return {
      iframeContainer,
      sizes,
      isDesktop,
      getColorTitle,
    }
  },
})
